<template>
  <div class="row new-waybill-exit-master">
    <modal :adaptive="true" height="auto" name="waybill-transition-history" width="1000">
      <waybill-transition-history-modal
          :order-id="wayBillExitState.orderNo"
          :transition-type="currentTransitionType"
          class="relative"
      ></waybill-transition-history-modal>
    </modal>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-5 py-3">
            <div class="row m-0 p-0 new-waybil-exit-border-master p-2">
              <div :class="{'col-12': !wayBillExitState.qr_link}" class="col-10 row m-0 p-0">
                <div class="row mb-7 col-12">
                  <div class="col-6">
                    <date-picker-input
                        :disabled="true"
                        :is-valid="true"
                        :title="$t('waybill.action_date')"
                    >
                    </date-picker-input>
                  </div>
                  <div class="col-6" style="display: none">
                    <custom-multi-select
                        :helper-text="$t('waybill.please_select_product_identification')"
                        :is-inline="true"
                        :model.sync="wayBillExitState.productIdentification"
                        :not-list="true"
                        :options="productIdentificationOptions"
                        :title="$t('waybill.product_identification')"
                        max="1"
                        name="wayBillExitStateProductIdentification"
                    >
                    </custom-multi-select>
                  </div>
                  <div class="col-6">
                    <custom-multi-select
                        :disabled="id"
                        :helper-text="$t('waybill.exit_type')"
                        :model.sync="waybill_type_id"
                        :options="waybillTypeOptions"
                        :title="$t('waybill.exit_type')"
                        max="1"
                        name="wayBillWaybillType"
                        not-list="true"
                        @save="supplier_company_id = null"
                    />
                  </div>
                  <div class="col-6">
                    <text-input
                        :disabled="true"
                        :helper-text="$t('waybill.please_enter_document_no')"
                        :is-inline="true"
                        :model.sync="document_no"
                        :placeholder="$t('waybill.please_enter_document_no')"
                        :title="$t('waybill.exit_document_no')"
                    />
                  </div>
                  <div v-if="waybill_type_id && waybill_type_id != 5" class="col-6">
                    <custom-multi-select
                        :disabled="id"
                        :max="1"
                        :model.sync="supplier_company_id"
                        :not-list="true"
                        :options="supplierCompanyOptions"
                        :title="$t('waybill.current_account')"
                        input-width="150px"
                        name="waybill_current_company"
                    ></custom-multi-select>
                  </div>

                  <div class="col-6">
                    <text-input
                        :helper-text="$t('waybill.please_enter_receiver')"
                        :is-inline="false"
                        :model.sync="delivering"
                        :placeholder="$t('waybill.please_enter_receiver')"
                        :title="$t('waybill.receiver')"
                    />
                  </div>

                  <div v-if="Number(waybill_type_id) === 8 || Number(waybill_type_id) === 7 || Number(waybill_type_id) === 6" class="col-6">
                    <label class="form-check-label">Fatura Kesilsin</label>
                    <br/>
                    <input v-model="billable" class="form-control-sm" type="checkbox">
                  </div>

                </div>
              </div>
            </div>

            <h3 v-if="isValidToContinue"
                v-b-toggle.order_form_entry
                class="cursor-pointer mt-1 d-flex justify-content-center" variant="info">
              <span v-html="getIconByKey('icons.waybill.down_arrow', {
               class: 'w-25px h-25px object-cover d-inline-block opacity-75'
                })">
                </span>
            </h3>
            <b-collapse
                v-if="isValidToContinue"
                id="order_form_entry" class="pt-1" visible>
              <template v-for="(waybillDetail, key) in orderDetails">
                <h4 v-b-toggle="`order_form_entry_detail_${key + 1}`"
                    class="d-inline-block cursor-pointer mt-3 text-primary bottom-border-1px-navy font-weight-boldest"
                    variant="info">
                  <span class="d-inline-block pr-2">{{ key + 1 }}. Satır</span>
                  <i class="fas fa-caret-down text-navy-blue opacity-75"></i>
                  <span v-if="orderDetails.length>1 && orderDetails.length!=1" class="cursor-pointer ml-1" @click="deleteWaybil(key)" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-20px h-20px d-inline-block mr-3 '
                      })"></span>

                </h4>
                <br>

                <b-collapse :id="`order_form_entry_detail_${key + 1}`" class="mb-5 new-waybil-exit-border-master p-2" visible>
                  <div class="row mt-5">
                    <div v-if="waybillDetail" class="col-12 d-flex justify-content-between content-between mt-2 mb-5">
                        <span
                            class="px-3 py-0 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm"
                            @click="showPreviousEntryTransitionHistory(key)">
                          <span v-html="getIconByKey('icons.waybill.history', {
                                  class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                            })">
                          </span>
                          {{ $t('waybill.entry_transition_history').toUpperCase() }}
                        </span>
                      <span
                          class="px-3 py-0 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm"
                          @click="showPreviousExitTransitionHistory(key)">
                          <span v-html="getIconByKey('icons.waybill.history', {
                                  class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                            })">
                          </span>
                          {{ $t('waybill.exit_transition_history').toUpperCase() }}
                        </span>
                    </div>

                    <div class="col-6">
                      <custom-multi-select
                          :has-bottom-margin="false"
                          :is-inline="false"
                          :model.sync="waybillDetail.instruction_type_id"
                          :options="instructionTypeOptions"
                          :title="$t('waybill.waybill_kind')"
                          input-width="150px"
                          max="1"
                          name="wayBillKind"
                          not-list="true"
                          @save="instructionTypeChanged($event, key)"
                      />
                    </div>
                    <div v-if="waybillDetail.instruction_type_id" class="col-6">
                      <custom-multi-select
                          :has-bottom-margin="false"
                          :is-inline="false"
                          :model.sync="waybillDetail.waybillable_ids"
                          :options="waybillDetail.orderOptions"
                          :title="$t('waybill.order_no')"
                          input-width="150px"
                          max="1"
                          name="waybillable_ids"
                          object-key="name"
                          @save="waybillableIdsChanged($event, key)"
                      />
                    </div>
                    <div v-if="waybillDetail.waybillable" class="col-6">
                      <text-input
                          :disabled="true"
                          :is-inline="false"
                          :model.sync="waybillDetail.waybillable.product_name"
                          :placeholder="$t('waybill.please_enter_product_name')"
                          :title="$t('waybill.product_name')"
                      />
                    </div>
                    <div v-if="waybillDetail.waybillable && waybillDetail.waybillable.product_name"
                         class="col-6">
                      <custom-multi-select
                          :disabled="waybillDetail.isSubmitted"
                          :is-inline="false"
                          :model.sync="waybillDetail.product_color_id"
                          :options="waybillDetail.waybillable.detail"
                          :placeholder="$t('waybill.please_enter_product_color')"
                          :title="$t('waybill.product_type_color')"
                          max="1"
                          name="wayBillOrderColor"
                          not-list="true"
                          object-key="color"
                          @save="onSelectProduct($event, key)"
                      >
                      </custom-multi-select>
                    </div>
                    <div v-if="waybillDetail.product_color_id" class="col-6 row mx-0">
                      <text-input
                          :disabled="true"
                          :is-inline="false"
                          :model.sync="waybillDetail.incoming_amount"
                          :title="$t('waybill.entry_net_amount')"
                      />
                    </div>

                    <div class="col-6 row mx-0">
                      <number-input
                          :disabled="waybillDetail.isSubmitted"
                          :is-inline="false"
                          :is-valid="waybillDetail.order_amount ? true : null"
                          :min="0"
                          :model.sync="waybillDetail.tare_amount"
                          :placeholder="$t('waybill.please_enter_tare_amount')"
                          :step="1"
                          :title="$t('waybill.tare_amount')"
                          name="remaining_amount"
                          step="1"
                      ></number-input>
                    </div>

                    <div class="col-6">
                      <number-input
                          :disabled="waybillDetail.isSubmitted"
                          :is-inline="false"
                          :is-valid="waybillDetail.fabric_roll ? true : null"
                          :min="0"
                          :model.sync="waybillDetail.fabric_roll"
                          :placeholder="$t('waybill.please_enter_number_of_roll')"
                          :step="1"
                          :title="$t('waybill.number_of_roll')"
                      ></number-input>
                    </div>
                    <div class="col-6">
                      <number-input
                          :disabled="waybillDetail.isSubmitted || waybillDetail.instruction_type_id == 7"
                          :is-inline="false"
                          :is-valid="waybillDetail.gross_amount ? true : null"
                          :min="0"
                          :model.sync="waybillDetail.gross_amount"
                          :placeholder="$t('waybill.please_enter_gross_amount')"
                          :step="1"
                          :title="$t('waybill.gross_amount')"
                      ></number-input>
                    </div>
                    <div class="col-6">
                      <text-input
                          :disabled="waybillDetail.isSubmitted"
                          :is-inline="false"
                          :is-valid="waybillDetail.part_number ? true : null"
                          :model.sync="waybillDetail.part_number"
                          :placeholder="$t('waybill.please_enter_part_number')"
                          :title="$t('waybill.part_number')"
                      ></text-input>
                    </div>
                    <div class="col-6">
                      <custom-multi-select
                          :disabled="true"
                          :is-inline="false"
                          :model.sync="waybillDetail.store_id"
                          :options="storeOptions"
                          :placeholder="$t('waybill.please_enter_depo_type')"
                          :title="$t('waybill.depo_type')"
                          max="1"
                          name="wayBillOrderStoreOptions"
                          not-list="true"
                      />
                    </div>

                    <div class="col-6">
                      <number-input
                          :disabled="waybillDetail.isSubmitted"
                          :helper-text="$t('waybill.please_enter_exit_net_amount')"
                          :is-inline="false"
                          :is-valid="waybillDetail.net_amount ? true : null"
                          :min="0"
                          :model.sync="waybillDetail.net_amount"
                          :placeholder="$t('waybill.please_enter_net_amount')"
                          :step="1"
                          :title="$t('waybill.exit_net_amount')"
                          :unit-model.sync="waybillDetail.unit_id"
                      ></number-input>
                    </div>

                    <div class="col-6 row mx-0">
                      <custom-multi-select
                          v-if="waybillDetail.waybillable"
                          :disabled="waybillDetail.isSubmitted"
                          :is-inline="false"
                          :model.sync="waybillDetail.unit_id"
                          :options="unitOptions"
                          :placeholder="$t('waybill.please_enter_product_color')"
                          :title="$t('waybill.unit')"
                          max="1"
                          name="wayBillOrderColor"
                          not-list="true"
                      >
                      </custom-multi-select>
                    </div>


                    <template v-if="waybillDetail.hasOwnProperty('data') && waybillDetail.data && waybillDetail.data">
                      <div class="flex-grow-1 mx-0">
                        <div
                            class="table-responsive h-100 navy-blue-border-top-1px navy-blue-border-left-1px navy-blue-border-bottom-1px border-15px">
                          <table class="table">
                            <thead>
                            <tr>
                              <th v-for="(value, key) in waybillDetail.data"
                                  :key="'size_and_color_body_size_name' + key"
                                  class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                                <div class="w-100 d-flex justify-content-center items-center">
                                  <span class="d-inline-block px-5">{{ value.name }}</span>
                                </div>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td v-for="(value, key) in waybillDetail.data"
                                  :key="'size_and_color_body_size_name' + key"
                                  class="navy-blue-border-right-1px">
                                <div class="d-flex justify-content-center">
                                  <input v-model="value.amount" class="form-control form-control-sm" type="text" @input="waybillDetailUpdate(waybillDetail)">
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </template>

                    <div v-if="wayBillExitState.exitInfo.length > 1" class="row mt-5 ">
                      <div class="col-12 mx-0 px-0">
                        <div class="row col-12 mx-0 mr-4">
                          <number-input-with-unit
                              :disabled="true"
                              :is-inline="false"
                              :model.sync="wayBillExitState.totalAmount.total_tare_amount"
                              :title="$t('waybill.total_tare_amount')"
                              :unit-model="wayBillExitState.unitId"
                              :unitOptions="unitOptions"
                          ></number-input-with-unit>
                        </div>
                        <div class="row col-12 mx-0">
                          <number-input-with-unit
                              :disabled="true"
                              :model.sync="wayBillExitState.totalAmount.total_net_amount"
                              :title="$t('waybill.total_exit_net_amount')"
                              :unit-model="wayBillExitState.unitId"
                              :unitOptions="unitOptions"
                          ></number-input-with-unit>
                        </div>
                      </div>
                    </div>

                    <template v-if="'extraAmount' in waybillDetail">
                      <div v-if="waybillDetail.extraAmount.length > 0" class="col-12 px-0 my-5 px-5">
                        <div v-for="(value, indexAmount) in waybillDetail.extraAmount" :key="indexAmount" class="row">
                          <div class="col-6 pr-0">
                            <number-input
                                :disabled="waybillDetail.isSubmitted"
                                :is-inline="false"
                                :min="0"
                                :model.sync="value.tare_amount"
                                :placeholder="$t('waybill.please_enter_tare_amount')"
                                :step="1"
                                :title="$t('waybill.tare_amount')"
                            ></number-input>
                          </div>
                          <div class="col-6 pr-0">
                            <number-input
                                :disabled="waybillDetail.isSubmitted"
                                :is-inline="false"
                                :min="0"
                                :model.sync="value.gross_amount"
                                :placeholder="$t('waybill.please_enter_gross_amount')"
                                :step="1"
                                :title="$t('waybill.gross_amount')"
                            ></number-input>
                          </div>
                          <div class="col-6 pr-0">
                            <number-input
                                :disabled="waybillDetail.isSubmitted"
                                :is-inline="false"
                                :min="0"
                                :model.sync="value.net_amount"
                                :placeholder="$t('waybill.please_enter_net_coming_amount')"
                                :step="1"
                                :title="$t('waybill.net_amount')"
                            ></number-input>
                          </div>
                        </div>
                      </div>
                      <div v-if="waybillDetail.extraAmount.length > 0" class="col-12 px-0 my-5 px-5">
                        <div class="row">
                          <div class="col-6 pr-0">
                            <number-input
                                :disabled="true"
                                :is-inline="false"
                                :min="0"
                                :model="totalAmountDetailProxy(key).tare_amount"
                                :step="1"
                                :title="$t('waybill.total_tare_amount')"
                            ></number-input>
                          </div>
                          <div class="col-6 pr-0">
                            <number-input
                                :disabled="true"
                                :is-inline="false"
                                :min="0"
                                :model="totalAmountDetailProxy(key).gross_amount"
                                :step="1"
                                :title="$t('waybill.total_gross_amount')"
                            ></number-input>
                          </div>
                          <div class="col-6 pr-0">
                            <number-input
                                :disabled="true"
                                :is-inline="false"
                                :min="0"
                                :model="totalAmountDetailProxy(key).net_amount"
                                :step="1"
                                :title="$t('waybill.total_net_amount')"
                            ></number-input>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 row justify-content-start items-end ml-2 my-5">
                        <span v-if="waybillDetail.extraAmount.length > 0"
                              @click="deleteMoreAmount(key, key)" v-html="getIconByKey('icons.sample.delete_red', {
                                  class: 'w-=25px h-25px object-cover item-end d-inline-block fond-red opacity-75 mr-2',
                            })">
                          </span>
                      </div>
                    </template>

                    <div
                        class="col-12 d-flex justify-content-between mb-1 items-center">
                        <span
                            class="px-3 py-1 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm"
                            @click="addMoreAmount(key)">
                          <span v-html="getIconByKey('icons.waybill.add_document', {
                                  class: 'w-20px h-20px object-cover d-inline-block opacity-75 mr-2',
                            })">
                          </span>
                          {{ $t('waybill.add_amount').toUpperCase() }}
                        </span>
                    </div>
                    <div
                        class="col-12 d-flex justify-content-start mb-10">
                        <span
                            class="px-3 py-1 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm"
                            @click="waybillDetail.showDescription = true ">
                          <span v-html="getIconByKey('icons.waybill.add_document', {
                                  class: 'w-20px h-20px object-cover d-inline-block opacity-75 mr-2',
                            })">
                          </span>
                          {{ $t('waybill.add_description').toUpperCase() }}
                        </span>
                    </div>
                    <div v-if="waybillDetail.showDescription" class="col-12">
                      <textarea-input
                          :is-inline="false"
                          :model.sync="waybillDetail.description"
                          :title="$t('waybill.description')"
                          name="description"
                      ></textarea-input>
                      <span @click="deleteWaybillDescription(key)" v-html="getIconByKey('icons.sample.delete_red', {
                                  class: 'w-25px h-25px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                            })">
                          </span>
                    </div>
                  </div>
                </b-collapse>
              </template>
            </b-collapse>

          </div>


          <div v-if="isValidToSubmit" class="col-12 row my-2">
            <div class="col-12 d-flex justify-content-center">
              <button-with-icon
                  :icon-name="null"
                  :text="$t('waybill.add_new_product').toUpperCase()"
                  class="mx-1 font-weight-boldest"
                  size="lg"
                  @onClick="addNewProduct()"
              ></button-with-icon>
              <button-with-icon
                  v-if="false"
                  :icon-name="null"
                  :text="$t('waybill.divide_product').toUpperCase()"
                  class="mx-1 font-weight-boldest"
                  size="lg"
                  @onClick="divideProduct(key, dp_index)"
              ></button-with-icon>
            </div>
          </div>

          <div class="row px-2 py-3 new-waybil-exit-border-master my-margin-exit-border-master" v-if="isValidToSubmit">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-2">
              Açıklamalar
              <span @click="addWaybillDescriptionGeneral()"
                    v-html="getIconByKey('icons.waybill.add_document', {
                          class: 'w-25px h-25px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                          })">
                  </span>
            </div>
            <template v-for="(item, index) in description">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 new-waybill-exit-description-text">
                <text-input
                    :has-bottom-margin="false"
                    :helper-text="$t('waybill.general_description')"
                    :is-inline="false"
                    :is-valid="description[index] == null ? null : true"
                    :model.sync="description[index]"
                    :placeholder="$t('waybill.general_description')"
                    :required="false"
                    title=""
                    :input-width="'100%'"
                    :input-max-width="'350px'"
                    :maxlength="50"
                    label-class="text-lg"
                ></text-input>
                <span @click="deleteWaybillDescriptionGeneral(index)"
                      v-html="getIconByKey('icons.sample.delete_red', {
                          class: 'w-25px h-25px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                          })">
                  </span>
              </div>
            </template>
          </div>



          <div v-if="isValidToSubmit" class="col-12 row px-0 mx-0 d-flex justify-content-center">
            <div class="d-flex justify-content-center mt-5">
              <span
                  class="px-3 green-border-1px rounded-full d-flex justify-content-center items-center w-125px mx-2 cursor-pointer"
                  @click="$router.go(-1)">
                <span v-html="getIconByKey('icons.waybill.give_up', {
                        class: 'w-35px h-35px object-cover d-inline-block opacity-75',
                  })">
                </span>
                {{ $t('general.give_up').toUpperCase() }}
              </span>
              <button-with-icon
                  :disabled="!isValidToSubmit"
                  :size="'lg'"
                  :text="$t('general.save').toUpperCase()"
                  icon-name="icons.waybill.save"
                  @onClick="submitWaybill"
              ></button-with-icon>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapState} from 'vuex'
import {BUNDLE, RESET_STATE} from "@/core/services/store/waybill/exit/new-waybill-exist.module";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import {CREATE_ITEM, GET_ITEM_DETAIL_BY_ID, GET_ITEMS, SET_LOADING} from "@/core/services/store/REST.module";
import NumberInput from "@/assets/components/inputs/NumberInput";
import TextInput from "@/assets/components/inputs/TextInput";
import NumberInputWithUnit from "@/assets/components/inputs/NumberInputWithUnit";
import WaybillTransitionHistoryModal from "@/view/pages/waybill/detail/layouts/WaybillTransitionHistoryModal";
import QrcodeVue from "qrcode.vue";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import moment from "moment";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import _ from "lodash";

export default {
  name: "NewWaybillExit",
  components: {
    ButtonWithIcon,
    TextInput,
    NumberInput,
    SelectInput,
    DatePickerInput,
    DashboardBox,
    NumberInputWithUnit,
    WaybillTransitionHistoryModal,
    QrcodeVue,
    CustomMultiSelect,
    TextareaInput
  },
  computed: {
    ...mapState({
      wayBillExitState: state => state.newWaybillExit[BUNDLE],
    }),
    totalAmountDetailProxy() {
      return function (key) {
        let self = this;
        let returnResult = {
          tare_amount: Number(self.orderDetails[key].tare_amount),
          gross_amount: Number(self.orderDetails[key].gross_amount),
          net_amount: Number(self.orderDetails[key].net_amount),
        }
        for (let i = 0; i < self.orderDetails[key].extraAmount.length; i++) {
          let temp = self.orderDetails[key].extraAmount[i];
          returnResult.tare_amount += Number(temp.tare_amount);
          returnResult.gross_amount += Number(temp.gross_amount);
          returnResult.net_amount += Number(temp.net_amount);
        }
        return returnResult;
      }
    },
    isValidToContinue() {
      if (!this.document_no) return false;
      if (!this.waybill_type_id) return false;
      if ((this.waybill_type_id && this.waybill_type_id == 5 && this.supplier_company_id != null) || (this.waybill_type_id && this.waybill_type_id != 5 && this.supplier_company_id == null)) return false;
      return true;
    },
    selected_waybillable_ids() {
      if (this.waybillable_ids === undefined || this.waybillable_ids === null) return [];
      let item = this.orderOptions[this.waybillable_ids];
      if (item) {
        this.wayBillExitState.orderNo = this.waybillable_ids[0];
        this.wayBillExitState.productName = item.product_name;
        let itemIndex = Object.keys(item.detail)[0];
        this.wayBillExitState.netIncomeAmount = Number(item.detail[itemIndex].total) - Number(item.detail[itemIndex].remaining);
        this.wayBillExitState.unitId = item.detail[itemIndex].amount_unit_id;
        this.wayBillExitState.numberOfIncomeRoll = item.detail[itemIndex].fabric_roll;
        this.wayBillExitState.incomePartNumber = item.detail[itemIndex].part_number;
        this.wayBillExitState.productColor = item.detail[itemIndex].color;
        this.wayBillExitState.instructable_type = item.detail[itemIndex].instructable_type;
        this.wayBillExitState.instructable_id = item.detail[itemIndex].instructable_id;
      }
      return this.orderOptions[this.waybillable_ids];
    },
    formattedOrderOptions() {
      let result = {}
      for (let i = 0; i < this.orderOptions.length; i++) {
        result[this.orderOptions[i].id] = this.orderOptions[i].order_number;
      }
      return result;
    },
    isValidToSubmit() {
      let orderDetails = this.orderDetails;
      let errors = [];

      let returnValue = true;
      orderDetails.forEach(item => {
        let currentWaybillDetail = item;
        if (!currentWaybillDetail || !currentWaybillDetail.waybillable) {
          returnValue = false;
          return false;
        }

        if (!currentWaybillDetail.waybillable || currentWaybillDetail.waybillable.product_name === null || currentWaybillDetail.waybillable.product_name.length === 0) {
          returnValue = false;
          return false;
        }
        if (Number(currentWaybillDetail.product_color_id) === 0) {
          returnValue = false;
          return false;
        }
        if (Number(currentWaybillDetail.tare_amount) === 0) {
          returnValue = false;
          return false;
        }
        if (Number(currentWaybillDetail.fabric_roll) === 0) {
          returnValue = false;
          return false;
        }
        if (Number(currentWaybillDetail.gross_amount) === 0) {
          returnValue = false;
          return false;
        }
        if (Number(currentWaybillDetail.net_amount) === 0) {
          returnValue = false;
          return false;
        }
        if (!currentWaybillDetail.part_number) {
          returnValue = false;
          return false;
        }
        if (Number(currentWaybillDetail.store_id) === 0) {
          returnValue = false;
          return false;
        }

        if (currentWaybillDetail.hasOwnProperty('extraAmount')) {
          for (let i = 0; i < currentWaybillDetail.extraAmount.length; i++) {
            let temp = currentWaybillDetail.extraAmount[i];
            if (Number(temp.tare_amount) === 0) {
              returnValue = false;
              return false;
            }
            if (Number(temp.gross_amount) === 0) {
              returnValue = false;
              return false;
            }
            if (Number(temp.net_amount) === 0) {
              returnValue = false;
              return false;
            }
          }
        }
      });
      return returnValue;
    },
  },
  data() {
    return {
      instructionTypeOptionsRaw: [],
      waybill_type_id: null,
      document_no: null,
      supplier_company_id: null,
      showOrderDetail: true,
      waybillable_ids: [],
      description: [],
      is_submitting: false,
      supplierCompanyOptions: [],
      companyUrl: 'api/supplier-companies',
      id: this.$route.params.id,
      orderOptions: [],
      productIdentificationOptions: [
        this.$t('general.please_select_option'),
        this.$t('waybill.by_scanning_qr_code'),
        this.$t('waybill.by_entering_info')
      ],
      waybillTypeOptions: [],
      unitOptions: {},
      storeOptions: {},
      instructionTypeOptions: [],
      orderUrl: 'api/orders',
      unitUrl: 'api/units',
      storeUrl: 'api/stores',
      billable: 0,
      transaction: {
        id: null,
        waybillable_id: null,
        waybillable_type: null,
        instructable_id: null,
        instructable_type: null,
        instruction_type_id: null,
        waybill_id: null,
        order_product_id: null,
        order_size_and_color_id: null,
        tare_amount: null,
        gross_amount: null,
        net_amount: null,
        unit_id: null,
        description: "",
        showDescription: false,
        part_number: null,
        fabric_roll: null,
        store_id: null,
        transaction_type: null,
        waybillable_ids: [],
        extraAmount: [],
        totalAmounts: [],
        divided_products: [],
        orderOptions: [],
        instructable: null,
        order_amount_unit_id: null,
        net_amount_unit_id: null,
        waybillable: null,
        product_color_id: null
      },
      defaultTransaction: {
        id: null,
        waybillable_id: null,
        waybillable_type: null,
        instructable_id: null,
        instructable_type: null,
        instruction_type_id: null,
        waybill_id: null,
        order_product_id: null,
        order_size_and_color_id: null,
        tare_amount: null,
        gross_amount: null,
        net_amount: null,
        unit_id: null,
        description: "",
        showDescription: true,
        part_number: null,
        fabric_roll: null,
        store_id: null,
        transaction_type: null,
        waybillable_ids: [],
        extraAmount: [],
        totalAmounts: [],
        divided_products: [],
        orderOptions: [],
        instructable: null,
        order_amount_unit_id: null,
        net_amount_unit_id: null,
        waybillable: null,
        product_color_id: null
      },
      orderDetails: [],
      currentTransitionType: null,
      delivering: null,
    };
  },
  methods: {
    deleteWaybil(index){
      if(this.orderDetails.length>1){
        this.orderDetails.splice(index, 1);
      }
    },
    addWaybillDescriptionGeneral() {
      if (this.description.length < 6) {
        this.description.push("");
      }
    },
    deleteWaybillDescriptionGeneral(key) {
      this.description.splice(key, 1)
    },
    waybillDetailUpdate(waybillDetail) {
      let amount = 0;
      let items = _.values(waybillDetail.data);
      items.forEach(subItem => {
        amount += Number(subItem.amount);
      })
      waybillDetail.net_amount = amount;
      waybillDetail.total_amount = amount;
    },
    setUpForEdit() {
      let self = this;
      self.$store.commit(SET_LOADING, true);
      self.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: 'api/waybills/' + self.id,
        acceptPromise: true,
      }).then((response) => {
        if (response.status) {
          let waybill = response.data;
          self.delivering = waybill.delivering;
          self.waybill_date = moment(waybill.waybill_date, LARAVEL_DATE_FORMAT);
          self.waybill_type_id = waybill.waybill_type_id;
          self.document_no = waybill.document_number;
          self.supplier_company_id = waybill.supplier_company_id;
          self.document = waybill.document;

          self.orderDetails = waybill.transactions;

          self.orderDetails.forEach((item, index) => {
            let hideLoading = self.orderDetails.length - 1 === index;
            this.getWaybillOrderOptions(item.instruction_type_id, index, item, hideLoading);
          });
        }
      })
    },
    deleteWaybillDescription(key) {
      this.orderDetails[key].description = '';
      this.orderDetails[key].showDescription = false;
    },
    addMoreAmount(index) {
      this.orderDetails[index].extraAmount.push({
        tare_amount: 0,
        gross_amount: 0,
        net_amount: 0,
      })
    },
    deleteMoreAmount(index, indexAmount) {
      this.orderDetails[index].extraAmount.splice(indexAmount, 1);
    },
    onSelectProduct(event, key) {
      let selectedItem = this.orderDetails[key].selectedOrderOption;
      let selectedItemDetail = selectedItem.detail[event.newValue[0]];

      this.orderDetails[key].waybillable_id = selectedItem.waybillable_id;
      this.orderDetails[key].waybillable_type = selectedItem.waybillable_type;
      this.orderDetails[key].instructable_id = selectedItemDetail.instructable_id
      this.orderDetails[key].instructable_type = selectedItemDetail.instructable_type;
      this.orderDetails[key].part_number = selectedItemDetail.part_number.toString();
      this.orderDetails[key].order_product_id = selectedItemDetail.order_product_id;
      this.orderDetails[key].order_size_and_color_id = selectedItemDetail.order_size_and_color_id;
      this.orderDetails[key].unit_id = selectedItemDetail.amount_unit_id;
      this.orderDetails[key].incoming_amount = selectedItemDetail.amount + " " + this.unitOptions[selectedItemDetail.amount_unit_id];
      this.orderDetails[key].net_incoming_amount = selectedItemDetail.amount;
      this.orderDetails[key].store_id = selectedItemDetail.store_id;
      this.orderDetails[key].tare_amount = selectedItemDetail.tare_amount;
      this.orderDetails[key].gross_amount = selectedItemDetail.gross_amount;
      this.orderDetails[key].fabric_roll = selectedItemDetail.fabric_roll.toString();
      this.orderDetails[key].waybill_transaction_id = selectedItemDetail.waybill_transaction_id;

      if (selectedItemDetail.hasOwnProperty('data')) {
        this.orderDetails[key].data = JSON.parse(selectedItemDetail.data);
      }

      let orderDetail = this.orderDetails[key];
      let instructableType = this.instructionTypeOptions[orderDetail.instruction_type_id];
      let orderNumber = orderDetail.orderOptions[orderDetail.waybillable_ids].name;
      let partNumber = orderDetail.part_number;
      let productName = orderDetail.orderOptions[orderDetail.waybillable_ids].product_name;
      let description = "Talimat Tipi: " + instructableType + " SN:" +   orderNumber + " PN:" + partNumber;
      description += " Ürün Adı: " + productName;
      orderDetail.description = description;
    },
    waybillableIdsChanged(event, key) {
      if (event.newValue[0]) {
        this.orderDetails[key].waybillable = null;
        this.orderDetails[key].product_color_id = null;
        this.orderDetails[key].selectedOrderOption = this.orderDetails[key].orderOptions[event.newValue[0]];
        this.orderDetails[key].waybillable = this.orderDetails[key].orderOptions[event.newValue[0]];
      }
    },
    instructionTypeChanged(event, key) {
      if (event.newValue[0]) {
        this.orderDetails[key].waybillable = null;
        this.orderDetails[key].product_color_id = null;
        this.orderDetails[key].waybillable_ids = [];
        this.orderDetails[key].selectedInstructionTypeOption = this.instructionTypeOptionsRaw.find(item => item.id == event.newValue[0]);
        this.getWaybillOrderOptions(event.newValue[0], key);
      }
    },
    getWaybillOrderOptions(instruction_type_id = null, orderIndex, item = null, hideLoading = false) {
      let instructionTypeId = instruction_type_id ? instruction_type_id : this.instruction_type_id;

      if (this.supplier_company_id != null && instructionTypeId != null) {
        this.$store.dispatch(GET_ITEMS, {
          url: 'api/waybill/waybill-exit-order-option',
          filters: {
            instruction_type_id: instructionTypeId,
            supplier_company_id: this.supplier_company_id,
            waybill_type_id: this.waybill_type_id,
            is_edit: this.id ? 1 : 0,
          },
          acceptPromise: true,
        }).then((result) => {
          let self = this;
          if (result.status) {
            self.orderDetails[orderIndex].orderOptions = result.data;
            if (item) {
              let prefix = "O";
              if (item.waybillable_type == "App\\Models\\Sample") {
                prefix = "S";
              }
              let orderOptions = result.data;

              let orderOption = prefix + "-" + item.waybillable_id;
              item.waybillable_ids = [prefix + "-" + item.waybillable_id];

              item.waybillable = null;
              item.product_color_id = null;
              item.selectedOrderOption = orderOptions[orderOption];
              item.waybillable = orderOptions[orderOption];
              let selectedItem = item.selectedOrderOption;
              let selectedItemDetail = selectedItem.detail[item.instructable_id];
              item.product_color_id = item.instructable_id;
              item.waybillable_id = selectedItem.waybillable_id;
              item.waybillable_type = selectedItem.waybillable_type;
              item.instructable_id = selectedItemDetail.instructable_id
              item.instructable_type = selectedItemDetail.instructable_type;
              item.order_product_id = selectedItemDetail.order_product_id;
              item.order_size_and_color_id = selectedItemDetail.order_size_and_color_id;
              item.unit_id = selectedItemDetail.amount_unit_id;
              item.divided_products = [];
              item.orderOptions = orderOptions;

              item.part_number = selectedItemDetail.part_number.toString();
              item.incoming_amount = selectedItemDetail.amount + " " + this.unitOptions[selectedItemDetail.amount_unit_id];
              item.net_incoming_amount = selectedItemDetail.amount;
              item.net_amount = selectedItemDetail.net_amount;
              item.store_id = selectedItemDetail.store_id;
              item.tare_amount = selectedItemDetail.tare_amount;
              item.gross_amount = selectedItemDetail.gross_amount;
              item.fabric_roll = selectedItemDetail.fabric_roll;

              if (selectedItemDetail.hasOwnProperty('data') && selectedItemDetail.data) {
                item.data = JSON.parse(selectedItemDetail.data);
              } else {
                delete item.data;
              }

              self.orderDetails[orderIndex] = item;

              if (hideLoading) {
                self.showOrderDetail = true;
                self.$store.commit(SET_LOADING, false);
              }
            }
          }
        });
      }
    },
    addNewAmount() {
      this.wayBillExitState.exitInfo.push({
        exit_net_amount: null,
        tare_amount: null,
        tare_amount_unit_id: 0,
        exit_number_of_roll: null,
        exit_part_number: null
      })
    },
    addNewProduct() {
      let object = _.clone(this.defaultTransaction);
      this.orderDetails.push(object);
    },
    divideProduct(key, sibling_id = null) {
      let parent_amount = this.orderDetails[key].net_amount;
      if (sibling_id !== null) {
        parent_amount = this.orderDetails[key].divided_products[sibling_id].amount;
      }
      this.orderDetails[key].divided_products.push({
        amount: null,
        sibling_id: sibling_id,
        parent_id: this.orderDetails[key].id,
        order_id: [],
        unit_id: this.orderDetails[key].unit_id,
        is_submitted: false,
        parent_amount: parent_amount,
      })
    },
    submitWaybill() {
      let self = this;

      if (!this.isUserGranted('Waybill', ['create', 'update'])) {
        return false;
      }

      if (this.is_submitting) return;
      this.is_submitting = true;
      if (!this.isValidToSubmit) {
        this.sweetAlertError('waybill.please_fill_all_the_required_fields_for_submission')
        this.is_submitting = false;
        return;
      }
      this.sweetAlertLoading();
      let currentOrderDetail = this.orderDetails;
      let form = new FormData();

      let transactions = [];
      let extraAmountError = null;

      this.orderDetails.forEach(transaction => {
        let tmpItem = {
          waybillable_id: transaction.waybillable_id,
          waybillable_type: transaction.waybillable_type,
          instructable_id: transaction.instructable_id,
          instructable_type: transaction.instructable_type,
          order_product_id: transaction.order_product_id,
          tare_amount: transaction.tare_amount,
          order_size_and_color_id: transaction.order_size_and_color_id,
          gross_amount: transaction.gross_amount,
          net_amount: transaction.net_amount,
          unit_id: transaction.unit_id,
          store_id: transaction.store_id,
          description: transaction.description,
          receiver: transaction.receiver,
          part_number: transaction.part_number,
          fabric_roll: transaction.fabric_roll,
          instruction_type_id: transaction.instruction_type_id,
          waybill_transaction_id: transaction.waybill_transaction_id
        };

        if (transaction.id) {
          tmpItem.id = transaction.id;
        }

        if (transaction.hasOwnProperty('extraAmount')) {
          transaction.extraAmount.forEach(extraAmount => {
            transaction.tare_amount = Number(transaction.tare_amount) + Number(extraAmount.tare_amount || 0);
            transaction.gross_amount = Number(transaction.gross_amount) + Number(extraAmount.gross_amount || 0);
            transaction.net_amount = Number(transaction.net_amount) + Number(extraAmount.net_amount || 0);
          })

          currentOrderDetail.extraAmount = [];
        }

        if (transaction.hasOwnProperty('extraAmount')) {
          transaction.extraAmount.forEach(extraAmount => {
            tmpItem.tare_amount = Number(transaction.tare_amount) + Number(extraAmount.tare_amount || 0);
            tmpItem.gross_amount = Number(transaction.gross_amount) + Number(extraAmount.gross_amount || 0);
            tmpItem.net_amount = Number(transaction.net_amount) + Number(extraAmount.net_amount || 0);
          })

          currentOrderDetail.extraAmount = [];
        }

        let incoming = transaction.net_incoming_amount;

        if (Number(tmpItem.net_amount) > Number(incoming)) {
          extraAmountError = true;
        }

        if (transaction.hasOwnProperty('data')) {
          tmpItem.data = JSON.stringify(_.cloneDeepWith(transaction.data));
        }

        transactions.push(tmpItem);
      })

      if (extraAmountError) {
        this.sweetAlertClose();
        this.sweetAlertError(this.$t('waybill.enter_amount_not_gt_remaining_amount'));
        this.is_submitting = false;
        return false;
      }

      form.append('waybill_date', moment(this.waybill_date).format(LARAVEL_DATE_FORMAT));
      form.append('document_number', this.document_no)
      form.append('supplier_company_id', this.supplier_company_id);
      form.append('waybill_type_id', this.waybill_type_id);
      form.append('description', currentOrderDetail.description);
      form.append('transaction_type', 0);
      form.append('description', JSON.stringify(this.description));

      if (this.billable) {
        form.append('billable', 1);
      } else {
        form.append('billable', 0);
      }

      form.append('delivering', this.delivering);

      this.createFormDataForList(form, 'transactions', transactions)

      this.$store.dispatch(CREATE_ITEM, {
        url: this.id ? 'api/waybills/' + this.id : 'api/waybills',
        acceptPromise: true,
        contents: form,
      }).then((result) => {
        if (result.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_saved')).then(() => {
            self.$router.push({name: 'waybill.index'});
          });
          self.is_submitting = false;
        } else {
          self.is_submitting = false;
          let response = result.data.response;
          if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
            self.sweetAlertError(response.data.message);
          } else {
            self.sweetAlertError(response.data);
          }
        }
        self.sweetAlertClose();
      });
    },
    showPreviousEntryTransitionHistory() {
      try {
        if (parseInt(this.wayBillExitState.orderNo) === 0) return;
      } catch (e) {
        return;
      }
      this.currentTransitionType = 1;
      this.$modal.show('waybill-transition-history');
    }
    ,
    showPreviousExitTransitionHistory() {
      try {
        if (parseInt(this.wayBillExitState.orderNo) === 0) return;
      } catch (e) {
        return;
      }
      this.currentTransitionType = 0;
      this.$modal.show('waybill-transition-history')
    }
  },
  created() {
    this.$store.commit(RESET_STATE);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('waybill.waybill'), route: "waybill.index"},
      {title: this.$t('waybill.product_exit')}
    ]);
    let self = this;

    if (this.isUserGranted('Waybill', ['create', 'update'])) {
      this.$store.dispatch(GET_ITEMS, {
        url: this.companyUrl,
        filters: {all: true},
        acceptPromise: true
      }).then((result) => {
        if (result.status) {
          self.supplierCompanyOptions = self.convertArrayToObjectByKey(result.data, 'id', 'name');
        }
      });

      this.$store.dispatch(GET_ITEMS, {
        url: 'api/instruction-type',
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          self.instructionTypeOptionsRaw = result.data;
          self.instructionTypeOptions = self.convertArrayToObjectByKey(result.data, 'id', 'translations.0.name');
        }
      })

      this.$store.dispatch(GET_ITEMS, {
        url: this.unitUrl,
        acceptPromise: true
      }).then((result) => {
        if (result.status) {
          self.unitOptions = self.convertArrayToObjectByKey(result.data, 'id', 'translations.0.name');
          self.unitOptions['0'] = self.$t('waybill.unit_type');
        }
      });

      this.$store.dispatch(GET_ITEMS, {
        url: 'api/waybill-types',
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          let tmpData = result.data.filter(item => +item.type === 1);
          self.waybillTypeOptions = self.convertArrayToObjectByKey(tmpData, 'id', 'translations.0.name');
        }
      });

      this.$store.dispatch(GET_ITEMS, {
        url: this.storeUrl,
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          let temp = result.data.filter(subItem => +subItem.parent_id !== 0);
          self.storeOptions = self.convertArrayToObjectByKey(temp, 'id', 'translations.0.name');
        }
      });

      this.$store.dispatch(GET_ITEMS, {
        url: 'api/waybill/generate-waybill-number',
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          if (!self.$route.params.id) {
            this.document_no = result.data.number;
          }
        }
      });

      if (this.$route.params.id) {
        this.setUpForEdit();
      } else {
        let object = _.clone(this.defaultTransaction);
        this.orderDetails.push(object);
      }
    }
  },
  watch: {
    supplier_company_id(newValue, oldValue) {
      if (!this.id) {
        this.orderDetails = [];
        let object = _.clone(this.defaultTransaction);
        this.orderDetails.push(object);
      }
    },
    waybill_type_id(newValue, oldValue) {
      if (!this.id) {
        this.orderDetails = [];
        let object = _.clone(this.defaultTransaction);
        this.orderDetails.push(object);
      }
    },
    orderDetails: {
      handler(newValue, oldValue) {
        if (newValue) {
          let self = this;
          newValue.forEach(item => {
            let unitValue = item.unit_id;
            this.remaining_amount_unit_id = unitValue;
            item.tare_amount_unit_id = unitValue;
            item.net_amount_unit_id = unitValue;
            item.remaining_amount_unit_id = unitValue;
            if (item.hasOwnProperty('data') && item.data) {
              let amount = 0;
              let items = _.values(item.data);

              items.forEach(subItem => {
                amount += Number(subItem.amount);
              })

              item.store_id = 8;
              item.net_amount = amount;
              item.total_amount = amount;

              if (+self.instruction_type_id === 7) {
                item.gross_amount = item.net_amount;
              }
            }
          })
        }
      }, deep: true
    },
  }
}
</script>

<style lang="scss">
/*Mobile Start*/
@import "../../../../assets/sass/mobile/waybill/newWaybillExit.scss";
/*Mobile End*/
</style>
